var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"13bda868d8b763977c8f6f36a5b268da003ff0e9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init as SentryInit } from '@sentry/nextjs';

import { environmentVariables, isClientSideEnvSet } from '@forms-exp/env';

const ENV_CHECK_INTERVAL = 500; // Half a second (500 ms)

// Initialize Sentry only after the environment variables are set.
const intervalId = setInterval(() => {
  if (isClientSideEnvSet()) {
    const SENTRY_DSN = environmentVariables.sentryDSN;
    const ENVIRONMENT = environmentVariables.environment;

    SentryInit({
      dsn: SENTRY_DSN,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.05,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // ...
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps,
      environment: ENVIRONMENT,
    });

    clearInterval(intervalId);
  }
}, ENV_CHECK_INTERVAL);
