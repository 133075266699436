import { FC, useState, useEffect, useRef } from 'react';
import Image from 'next/image';

import {
  NakedButton,
  XIconSmall,
  Text,
  useAlert,
  SecondaryButton,
} from '@weave/design-system';

import {
  fileSelectorContainerStyle,
  imagePreviewContainerStyle,
  emptyAttachmentStyle,
} from './file-input-field.styles';
import { checkFileSize, isAllowedFileType } from '@/utils';

interface FileInputFieldProps {
  type: 'front' | 'back';
  reviewMode?: boolean;
  [key: string]: any;
}

const NoAttachment = () => (
  <div css={emptyAttachmentStyle}>
    <Text>No picture attached!</Text>
  </div>
);

const FileInputField: FC<FileInputFieldProps> = ({
  type,
  value,
  id,
  onRemove,
  reviewMode,
  ...otherProps
}) => {
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string | null>(null);
  const previousValueRef = useRef<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { name } = otherProps;
  let imageElement;

  const alert = useAlert();

  useEffect(() => {
    if (value[type] && previousValueRef.current !== value[type]) {
      const file = value[type];
      previousValueRef.current = file;

      const checkFileSizeResp = checkFileSize(file);
      isAllowedFileType(file).then((isAllowedType) => {
        if (checkFileSizeResp.success && isAllowedType.success) {
          setImagePreviewSrc(URL.createObjectURL(file));
        } else {
          removeImageHandler();
          !checkFileSizeResp.success
            ? alert.error(`${checkFileSizeResp.errorMessage}`)
            : alert.error(`${isAllowedType.errorMessage}`);
        }
      });
    }
  }, [imagePreviewSrc, type, value]);

  useEffect(() => {
    return () => {
      if (imagePreviewSrc) {
        URL.revokeObjectURL(imagePreviewSrc);
      }
    };
  }, [imagePreviewSrc]);

  if (imagePreviewSrc) {
    imageElement = (
      <Image src={imagePreviewSrc} alt={`${type} preview`} width="150" height="100" />
    );
  }

  if (reviewMode) {
    return <div css={imagePreviewContainerStyle}>{imageElement || <NoAttachment />}</div>;
  }

  function removeImageHandler() {
    setImagePreviewSrc(null);
    onRemove(name, type);

    // Clear the file input value so that the user can upload the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  return (
    <>
      {imagePreviewSrc ? (
        <div css={imagePreviewContainerStyle}>
          {imageElement}

          <NakedButton className="remove-button" onClick={() => removeImageHandler()}>
            <XIconSmall /> &nbsp;Remove
          </NakedButton>
        </div>
      ) : (
        <div css={fileSelectorContainerStyle}>
          <SecondaryButton>Picture of {type}</SecondaryButton>
          <input
            type="file"
            //accept="image/*,android/force-camera-workaround"
            accept="image/*;capture=camera,image/*"
            //accept="image/*"
            //capture="environment"
            id={`${id}-${type}`}
            ref={fileInputRef}
            {...otherProps}
          />
        </div>
      )}
    </>
  );
};

export default FileInputField;
